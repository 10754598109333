<template>
  <div class='school'>
    <div class='globle_border' v-loading="loading">
      <div class="search">
        <el-input v-model="search" placeholder="搜索学校名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-button type="primary" @click="goToPage('add', '')">新增</el-button>
      </div>
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" height="580">
          <el-table-column prop="number" label="序号" width='80'>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="schoolCode" label="学校编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="schoolName" label="学校名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="contactMan" label="联系人"  show-overflow-tooltip></el-table-column>
          <el-table-column prop="contactTel" label="联系电话"  show-overflow-tooltip></el-table-column>
          <el-table-column prop="address" label="地址" show-overflow-tooltip></el-table-column>
          <!-- <el-table-column prop="remark" label="简介" show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-html="scope.row.remark"></div>
            </template>
          </el-table-column> -->
          <el-table-column label="操作" header-align="center" align="center"  width="250" fixed="right">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" size="" plain @click="goToPage('info', scope.row)">查看</el-button>
              <el-button class="btn" type="" size="" plain @click="goToPage('edit', scope.row)">修改</el-button>
              <el-button class="btn" type="danger" size="" plain @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      search: '',
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created () {
    this.getSchoolList()
  },
  methods: {
    // 学校列表
    getSchoolList () {
      this.loading = true
      this.$axios.get(this.$api.schoolList, {
        params: {
          schoolName: this.search,
          page: this.currentPage,
          pageSize: this.pageSize
        }
      }).then((res) => {
        this.tableData = res.data.result.list
        this.totalItemsCount = res.data.result.totalCount
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    // 搜索
    onSearch () {
      this.currentPage = 1
      this.getSchoolList()
    },
    // 跳转
    goToPage (str, row) {
      const { id } = row
      switch (str) {
        case 'add':
          this.$router.push({ path: '/school/detail/add', query: { id } })
          break;
        case 'edit':
          this.$router.push({ path: '/school/detail/edit', query: { id } })
          break;
        case 'info':
          this.$router.push({ path: '/school/detail/info', query: { id } })
          break;
      }
    },
    // 删除
    handleDelete (index, row) {
      this.$confirm('此操作将永久删除该学校信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$axios.post(this.$api.schoolDelete, {
          id: row.id
        }).then((res) => {
          if (res.data.code == 100) {
            this.$message.success(res.data.desc)
          }
          this.getSchoolList()
        }).catch(() => { })
      }).catch(() => { });
    },
    // 切换每页显示的条数
    handleSizeChange (e) {
      this.pageSize = e
      this.getSchoolList()
    },
    // 换页
    handleCurrentChange (e) {
      this.currentPage = e
      this.getSchoolList()
    },
  },
}
</script>

<style scoped lang='scss'>
.school {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;

      ::v-deep .el-input__inner {
        border-radius: 10px;
      }
    }

    .el-button {
      margin-left: 20px;
    }
  }
}
</style>
